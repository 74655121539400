<template>
    <div>
        <pop ref="pop" title="小助手统计" width="50%" @toAudit="$refs.pop.show = false" :footer="false">
            <div class="nav">
                <div class="item">
                    <improvePersonnel v-model="search.amId"></improvePersonnel>
                </div>
                <div class="item">
                    <div-date-picker dataType="daterange" :timeStr.sync="search.time"></div-date-picker>
                </div>
                <el-button type="primary" size="small" @click="getlist">搜索</el-button>
            </div>
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column label="管理员姓名" prop="name"> </el-table-column>
                <el-table-column label="新增微信会员" prop="addUserAll"> </el-table-column>
                <el-table-column label="新增内部会员（开发者）" prop="addDeveloper"> </el-table-column>
                <el-table-column label="新增内部会员（其他）" prop="addGuestProvider"> </el-table-column>
            </el-table>
        </pop>
    </div>
</template>

<script>
import improvePersonnel from "./improve-personnel.vue";
import list from "@/mixin/list";
export default {
    mixins: [list],
    methods: {
        init() {
            this.$refs.pop.show = true;
            this.getlist();
        },
        async getlist() {
            let { data: res } = await this.$http.get("/admin/UserGuest/statisc", { params: this.search });
            // console.log(res);
            this.list = res.data;
        },
    },
    components: {
        improvePersonnel,
    },

    watch: {
        search(newValue, oldValue) {},
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
.nav {
    display: flex;
    > * {
        flex-shrink: 0;
        margin-right: 20px;
    }
}
</style>
