<template>
    <div>
        <el-card>
            <nav class="out">
                <nav class="input">
                    <el-input v-model="form_2.keyword" size="medium" placeholder="输入姓名，手机，邮箱，公司" />
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.type" placeholder="类型" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.business" placeholder="行业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="item in businessList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.subBusiness" placeholder="二级行业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="item in subBusinessList" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.waId" placeholder="小助手" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="已绑定小助手" :value="-2" />
                        <el-option label="未绑定小助手" :value="-3" />
                        <el-option
                            v-for="item in $store.state.NormalList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 355px">
                    <label for="">城市</label>
                    <el-select
                        v-model="form_2.province"
                        placeholder="省份"
                        size="medium"
                        style="width: 150px"
                        class="ml"
                        @change="getc(form_2.province)"
                    >
                        <el-option label="全部" :value="-1" />

                        <el-option v-for="item in p_arr" :key="item.code" :label="item.name" :value="item.code" />
                    </el-select>
                    <el-select v-model="form_2.city" placeholder="城市" size="medium" style="width: 150px" class="ml">
                        <el-option label="全部" :value="-1" />
                        <el-option v-for="item in c_arr" :key="item.code" :label="item.name" :value="item.code" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <improve-personnel :value.sync="form_2.amId"></improve-personnel>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.bindEnterprise" placeholder="绑定企业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="未绑定" :value="0" />
                        <el-option label="已绑定" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.jobLevel" placeholder="职级" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel"
                            :key="index"
                            :label="item"
                            :value="index"
                        />
                    </el-select>
                </nav>
                <nav class="input flex-align" style="width: 300px;display:flex">
                    <span for="" style="flex-shrink:0">完善时间</span>
                    <el-date-picker
                        v-model="form_2.completeTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;margin-left: 10px;"
                        size="medium"
                    />
                </nav>
                <nav class="input flex-align" style="width: 300px;display:flex">
                    <span for="" style="flex-shrink:0">创建时间</span>
                    <el-date-picker
                        v-model="form_2.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;margin-left: 10px;"
                        size="medium"
                    />
                </nav>
                <nav class="input" style="width: 35px;">
                    <el-button size="small" @click="reset">重置</el-button>
                </nav>
                <nav class="input" style="width: auto;margin-right: 30px;">
                    <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
                </nav>
                <nav class="input" style="width: 65px">
                    <el-button
                        v-show="$store.state.btn_if.indexOf('user_guest_down_excel') != -1"
                        type="primary"
                        size="small"
                        @click="beforedown"
                        >{{ btn ? "导出Excel" : "导出中.." }}</el-button
                    >
                </nav>

                <nav class="input" style="width: 65px">
                    <el-button
                        v-show="$store.state.btn_if.indexOf('user_guest_import_excel') != -1"
                        type="primary"
                        size="small"
                        @click="Import"
                        >{{ impEX }}</el-button
                    >
                </nav>
                <nav class="input" style="width: auto">
                    <el-button
                        :loading="importExcel"
                        v-show="$store.state.btn_if.indexOf('user_guest_import_excel') != -1"
                        type="primary"
                        size="small"
                        @click="onDownload"
                        >{{ "下载导入Excel模板" }}</el-button
                    >
                </nav>
                <nav class="input" style="width: 83px">
                    <el-button
                        v-show="$store.state.btn_if.indexOf('user_guest_redundant') != -1"
                        type="primary"
                        size="small"
                        @click="show_s"
                        >{{ reveal ? "显示冗余数据" : "隐藏冗余数据" }}</el-button
                    >
                </nav>
                <nav class="input" style="width: auto">
                    <el-button type="primary" size="small" @click="$refs.sendSms.init(form_2)">
                        添加到短信收件人
                    </el-button>
                </nav>
                <nav class="input" style="width: 60px">
                    <router-link to="/user_guest_edit">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_guest_add') != -1"
                            type="primary"
                            size="small"
                            >添加会员</el-button
                        >
                    </router-link>
                </nav>
                <nav class="input" style="width: auto;margin-right: 10px;">
                    <el-button type="primary" size="small" @click="$refs.pop.show = true">同步注册信息</el-button>
                </nav>
                <nav class="input" style="width: auto">
                    <el-button type="primary" :loading="syncEnterpriseLoad" size="small" @click="syncEnterprise"
                        >同步企业信息</el-button
                    >
                </nav>
                <nav class="input" style="width: auto">
                    <el-button type="primary" size="small" @click="$refs.helperStatistics.init()">小助手统计</el-button>
                </nav>
                <nav class="input" style="width: 60px">
                    <el-badge :value="total_form.allTotal" class="item" type="primary">
                        <el-button size="small">总数量</el-button>
                    </el-badge>
                </nav>
                <nav class="input" style="width: 150px;margin-left:35px">
                    <el-badge :value="total_form.existPhoneTotal" class="item" type="primary">
                        <el-button size="small">手机号不为空总数量</el-button>
                    </el-badge>
                </nav>
            </nav>
        </el-card>
        <el-dialog title="" :visible.sync="dioA" width="30%" @close="">
            <Show @form="checkf" />
            <span slot="footer">
                <el-button @click="dioA = false">取消</el-button>
                <el-button type="primary" @click="download">{{ btn ? "导出" : "导出中.." }}</el-button>
            </span>
        </el-dialog>

        <el-card style="margin-top: 10px;">
            <el-table
                :data="reveal ? list : table"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column prop="id" width="100" label="编号" />
                <el-table-column prop="realName" label="姓名">
                    <template slot-scope="{ row }">
                        {{ row.realName }}
                        <el-tag type="danger" size="mini" effect="dark" @click="unfold(row)"> {{ row.num }}</el-tag>
                        <div style="margin-top: 5px;">
                            <el-tag v-if="fUser(row)" size="small" :type="fUserColor(row)">
                                {{ fUser(row) }}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="desc" label="简介"> </el-table-column> -->
                <el-table-column prop="company" label="公司">
                    <template slot-scope="{ row }">
                        <p>{{ row.company }}</p>
                        <el-tag size="small" :type="row.enterpriseId > 0 ? 'warning' : 'info'">
                            {{ fEnterpriseId(row) }}
                        </el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="job" label="职务对外名称"> </el-table-column> -->
                <el-table-column prop="phone" label="手机号" width="130">
                    <template slot-scope="{ row }">
                        {{ row.phone }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型" width="130">
                    <template slot-scope="{ row }">
                        <!-- {{ row.type | Ftype }} -->
                        {{ row.typeName }}
                        <!-- <el-select v-model="row.type" multiple @change="Stype(row)">
                        <el-option v-for="(item,i) in $store.state.config.type" :key="i" :label="item" :value="i" />
                    </el-select> -->
                    </template>
                </el-table-column>
                <el-table-column prop="businessName" label="行业" width="130">
                    <template slot-scope="{ row }">
                        <!-- <el-select v-model="row.business" placeholder="行业" multiple :multiple-limit="3" @change="Sbusiness(row)">
                        <el-option v-for="(item, index) in $store.state.config &&
                  $store.state.config.business" :key="index" :label="item" :value="index" />
                    </el-select> -->
                        {{ row.businessName }}
                    </template>
                </el-table-column>
                <el-table-column prop="subBusinessName" label="子行业"> </el-table-column>
                <el-table-column prop="job" label="职位">
                    <template slot-scope="{ row }">
                        <span v-if="$store.state.btn_if.indexOf('user_guest_remark') == -1"> {{ row.job }}</span>
                        <Remark
                            v-else
                            rowKey="job"
                            :row="row"
                            url="/admin/UserGuest/editInfo"
                            @getlist="getlist(row)"
                        ></Remark>
                    </template>
                </el-table-column>
                <el-table-column prop="jobLevelName" label="级别">
                    <template slot-scope="{ row }">
                        <el-select v-model="row.jobLevel" @change="S_jobLevel(row)">
                            <el-option
                                v-for="(item, index) in $store.state.config.userJobLevel"
                                :key="index"
                                :label="item"
                                :value="index * 1"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="cityName" label="城市">
                    <template slot-scope="{ row }">
                        <span>{{ row.provinceName }}</span
                        >-<span>{{ row.cityName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="活动" width="210">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="item in !!row.activityName && row.activityName.split(',')"
                            :key="item"
                            type="info"
                            size="mini"
                            effect="dark"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                    <template slot-scope="{ row }">
                        <!-- <div class="input-box">
                            <el-input v-show="row.pai" v-model="row.remark" size="small" class="id" :autofocus="row.pai" @blur="handleInputBlur(row)" />
                        </div>
                        <span v-show="!row.pai" @click="$store.state.btn_if.indexOf('user_guest_remark') != -1 && showinput(row)"> {{ row.remark }}</span>
                        <i
                            v-show="!row.pai"
                            :class="$store.state.btn_if.indexOf('user_guest_remark') != -1 && 'el-icon-edit'"
                            @click="$store.state.btn_if.indexOf('user_guest_remark') != -1 && showinput(row)"
                        /> -->
                        <Remarks
                            v-if="$store.state.btn_if.indexOf('user_guest_remark') != -1"
                            :row="row"
                            url="/admin/UserGuest/editRemark"
                            @getlist="getlist"
                            :val="{ url: '/admin/UserGuest/editRemark', pw: 'user_guest_remark', user:'是否将备注同步至微信会员和高管库' }"

                        ></Remarks>
                        <span v-else>{{ row.remark }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="activityName" label="活动"> </el-table-column> -->
                <el-table-column prop="waId" label="小助手" width="100">
                    <template slot-scope="{ row }">
                        <el-select
                            v-model="row.waId"
                            placeholder="小助手"
                            multiple
                            :multiple-limit="3"
                            size="medium"
                            :disabled="!QX('user_guest_assistant')"
                            @change="chagne(row)"
                            @visible-change="visibleChange"
                            @remove-tag="visibleChange()"
                        >
                            <el-option
                                v-for="item in $store.state.NormalList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="时间" />
                <el-table-column prop="completeInfo" label="信息完善情况">
                    <template slot-scope="{ row }">
                        <el-tag type="warning">{{ row.completeInfo == 0 ? "未完善" : "已完善" }}</el-tag>
                        <template v-if="row.completeInfo == 1">
                            <p>完善人员：{{ row.manager }}</p>
                            <p>完善时间：{{ row.completeInfoTime }}</p>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="ew">
                        <router-link :to="'/user_guest_edit?id=' + ew.row.id">
                            <el-button
                                v-show="$store.state.btn_if.indexOf('user_guest_edit') != -1"
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                class="skyblue"
                                @click="edit(ew.row.id)"
                                >编辑</el-button
                            >
                        </router-link>

                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_guest_del') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="open(ew.row.id, 1)"
                            >删除</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_guest_show') != -1"
                            type="text"
                            size="mini"
                            icon="el-icon-camera"
                            class="green"
                            @click="information(ew.row)"
                            >查看信息</el-button
                        >
                        <el-button
                            v-if="ew.row.enterpriseId > 0"
                            type="text"
                            icon="el-icon-s-release"
                            size="mini"
                            @click="solutionEnterprise(ew.row)"
                            >解绑企业</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                v-show="reveal"
                :current-page="form_2.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="pagesize"
                layout="total, prev, pager, next,jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
            <el-pagination
                v-show="!reveal"
                :current-page="currentPage2"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="pagesize"
                layout="total, prev, pager, next,jumper"
                :total="total2"
                @current-change="handleCurrentChange2"
            />
        </el-card>

        <el-dialog title="" :visible.sync="phone_data" width="80%">
            <el-table
                :data="phonelist"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column prop="id" width="100" label="编号" />
                <el-table-column prop="realName" label="姓名">
                    <template slot-scope="{ row }">
                        {{ row.realName }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="desc" label="简介"> </el-table-column> -->
                <el-table-column prop="company" label="公司" />
                <!-- <el-table-column prop="job" label="职务对外名称"> </el-table-column> -->
                <el-table-column prop="phone" label="手机号" width="130">
                    <template slot-scope="{ row }">
                        {{ row.phone }}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型" width="130">
                    <template slot-scope="{ row }">
                        <!-- {{ row.type | Ftype }} -->
                        <!-- <el-select v-model="row.type" multiple @change="Stype(row,3)">
                        <el-option v-for="(item,i) in $store.state.config.type" :key="i" :label="item" :value="i" />
                    </el-select> -->
                        {{ row.typeName }}
                    </template>
                </el-table-column>
                <el-table-column prop="businessName" label="行业" width="130">
                    <template slot-scope="{ row }">
                        <!-- <el-select v-model="row.business" placeholder="行业" multiple :multiple-limit="3" @change="Sbusiness(row,3)">
                        <el-option v-for="(item, index) in $store.state.config &&
                  $store.state.config.business" :key="index" :label="item" :value="index" />
                    </el-select> -->
                        {{ row.businessName }}
                    </template>
                </el-table-column>
                <el-table-column prop="subBusinessName" label="子行业"> </el-table-column>
                <el-table-column prop="job" label="职位">
                    <template slot-scope="{ row }">
                        <!-- <div class="input-box">
                            <el-input v-show="row.pai_1" v-model="row.job" size="small" class="id" :autofocus="row.pai_1" @blur="handleInputBlur_1(row, 3)" />
                        </div>
                       
                        <i
                            v-show="!row.pai_1"
                            :class="$store.state.btn_if.indexOf('user_guest_remark') != -1 && 'el-icon-edit'"
                            @click="$store.state.btn_if.indexOf('user_guest_remark') != -1 && showinput_1(row, 3)"
                        /> -->
                        <span v-if="$store.state.btn_if.indexOf('user_guest_remark')"> {{ row.job }}</span>
                        <Remark
                            v-else
                            rowKey="job"
                            :row="row"
                            url="/admin/UserGuest/editInfo"
                            @getlist="getphoneid(row)"
                        ></Remark>
                    </template>
                </el-table-column>
                <el-table-column prop="jobLevelName" label="级别">
                    <template slot-scope="{ row }">
                        <el-select v-model="row.jobLevel" @change="S_jobLevel(row, 3)">
                            <el-option
                                v-for="(item, index) in $store.state.config.userJobLevel"
                                :key="index"
                                :label="item"
                                :value="index * 1"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="cityName" label="城市">
                    <template slot-scope="{ row }">
                        <span>{{ row.provinceName }}</span
                        >-<span>{{ row.cityName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="活动" width="210">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="item in !!row.activityName && row.activityName.split(',')"
                            :key="item"
                            type="info"
                            size="mini"
                            effect="dark"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>

                <el-table-column prop="remark" label="备注">
                    <template slot-scope="{ row }">
                        <Remark
                            v-if="$store.state.btn_if.indexOf('user_guest_remark') != -1"
                            :row="row"
                            url="/admin/UserGuest/editRemark"
                            @getlist="getphoneid(row)"
                        ></Remark>
                        <span v-else>{{ row.remark }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="activityName" label="活动"> </el-table-column> -->
                <el-table-column prop="waId" label="小助手" width="120">
                    <template slot-scope="{ row }">
                        <el-select
                            v-model="row.waId"
                            placeholder="小助手"
                            multiple
                            :multiple-limit="3"
                            size="medium"
                            @change="chagne(row, 3)"
                        >
                            <el-option
                                v-for="item in $store.state.NormalList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="时间" />

                <el-table-column label="操作" width="150">
                    <template slot-scope="ew">
                        <router-link :to="'/user_guest_edit?id=' + ew.row.id">
                            <el-button
                                v-show="$store.state.btn_if.indexOf('user_guest_edit') != -1"
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                class="skyblue"
                                @click="edit(ew.row.id)"
                                >编辑</el-button
                            >
                        </router-link>
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="open(ew.row.id, 3, ew.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="信息" :visible.sync="showinfo" width="40%">
            <el-form ref="form" :model="form_show" label-width="80px" :inline="false" size="normal">
                <el-form-item label="手机号">
                    <!-- <el-input v-model="form_show.phone"></el-input> -->
                    <span>{{ form_show.phone }}</span>
                </el-form-item>
                <el-form-item label="邮箱">
                    <!-- <el-input v-model="form_show.email"></el-input> -->
                    <span>{{ form_show.email }}</span>
                </el-form-item>
                <el-form-item label="企业邮箱">
                    <!-- <el-input v-model="form_show.businessEmail"></el-input> -->
                    <span>{{ form_show.businessEmail }}</span>
                </el-form-item>
                <el-form-item label="微信">
                    <!-- <el-input v-model="form_show.wechat"></el-input> -->
                    <span>{{ form_show.wechat }}</span>
                </el-form-item>
                <el-form-item label="公司地址">
                    <!-- <el-input v-model="form_show.address"></el-input> -->
                    <span>{{ form_show.address }}</span>
                </el-form-item>
            </el-form>
        </el-dialog>
        <pop ref="pop" title="将根据手机号从注册会员数据中同步备注和小助手到内部会员" @toAudit="toSync">
            <el-radio v-model="registeringSynchronization" :label="1">将注册会员信息完全覆盖到内部会员</el-radio>
            <br /><br />
            <el-radio v-model="registeringSynchronization" :label="2">只覆盖内部会员数据为空的字段</el-radio>
        </pop>
        <helperStatistics ref="helperStatistics"></helperStatistics>
        <send-sms ref="sendSms" type="3"></send-sms>
    </div>
</template>

<script>
let that;

import $ from "jquery";
// import rules from '../../utils/rules'
import show from "../../components/countDowns/show.vue";
import { editInfo } from "../../api/upload.model.js";
import improvePersonnel from "./component/improve-personnel";
import helperStatistics from "./component/helperStatistics.vue";
import Remarks from "@/components/remark/index.vue";
export default {
    components: {
        Show: show,
        improvePersonnel,
        helperStatistics,
      Remarks
    },
    filters: {
        // Ftype(val) {
        //   return that.$store.state.config.user_type[val]
        // },

        getarea: function(value) {
            // console.log(that.area.reportArea,value);
            if (that.area.reportArea) {
                var reportArea = that.area.reportArea[value];
                return reportArea;
            }
        },
        getbu(value) {
            if (that.area.reportBusiness) {
                var reportBusiness = that.area.reportBusiness[value];
            }
            return reportBusiness;
        },
        getviewType(val) {
            if (val == 1) {
                return "在线查看";
            } else {
                return "下载";
            }
        },
    },
    data() {
        return {
          row:{},
            phonelist: [],
            registeringSynchronization: 2,
            total_form: {
                allTotal: 0,
                existPhoneTotal: 0,
            },
            importExcel: false,
            proof: false,
            reveal: true,
            table: [],
            impEX: "导入Excel",
            dioA: false,
            showinfo: false,
            date: [],
            title: "",
            year: "",
            publishStatus: "",
            reportArea: "",
            reportBusiness: "",
            order: "",
            confing: "",
            btn: true,
            action: {
                action: "report",
            },
            dioaddkuai: false,
            currentPage: 1,
            pagesize: 10,
            list: [],
            pai: [],
            paiform: {},
            value: "",
            dialogVisible: false,
            dialogVisiblexui: false,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            total: 0,
            form: {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_1: {
                id: "",
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_2: {
                page: 1,
                pageSize: 10,
                keyword: "",
                waId: "",
                business: "",
                city: "",
                type: "",
                amId: "",
                bindEnterprise: "",
                completeTime: [],
                createTime: [],
                subBusiness: "",
                // order: ''
            },
            form_3: {
                phone: "15080329619",
                smsCode: "",
            },
            imageUrl: "",
            imageUrl_1: "",
            timeout: null,
            header: {
                Token: sessionStorage.getItem("token"),
            },
            form_show: {},
            area: [],
            p_arr: [],
            c_arr: [],
            total2: 1,
            currentPage2: 1,
            phone_data: false,
            state_a: false,
            typeList: [],
            businessList: [],
            subBusinessList: [],
            syncEnterpriseLoad: false,
        };
    },
    computed: {
        // Fbusiness() {
        //   return function(row) {
        //     return (
        //       this.$store.state.config.user_business[row.type] &&

        //       this.$store.state.config.user_business[row.type][row.business]
        //     )
        //   }
        // },
        jobLevel() {
            return function(row) {
                return this.$store.state.config.userJobLevel[row.jobLevel];
            };
        },
        QX() {
            return function(val) {
                return this.$store.state.btn_if.indexOf(val) != -1;
            };
        },
    },
    beforeCreate() {
        that = this;
    },
    destroyed() {
        clearInterval(this.timeout);
    },
    created() {
        // sessionStorage.setItem('action', 'report')
        this.getp();
    },
    mounted() {
        this.timeout = setInterval(() => {
            var ifs = JSON.parse(sessionStorage.getItem("adf"));
            // console.log(ifs)
            if (ifs) {
                this.phone_data = !this.phone_data;
                sessionStorage.setItem("adf", false);
                // console.log(this.$store.state.Did)
                this.getphoneid({
                    id: this.$store.state.Did,
                });
                this.getlist();
                this.get_data();
            } else {
                if (this.$store.state.counts < 1) {
                    this.getlist();
                    this.get_data();
                    this.$store.state.counts++;
                } else {
                    clearInterval(this.timeout);
                }
            }
        }, 1000);
        this.getType();
    },
    watch: {
        "form_2.type"(newValue, oldValue) {
            this.form_2.business = "";
            this.form_2.subBusiness = "";
            this.getType("1");
        },
        "form_2.business"(newValue, oldValue) {
            this.form_2.subBusiness = "";
            this.getType("2");
        },
    },
    activated() {
        this.getlist();
    },
    methods: {
        async syncEnterprise() {
            await this.$confirm("是否同步企业信息?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            try {
                this.syncEnterpriseLoad = true;
                let { data } = await this.$http.post("/admin/UserGuest/syncEnterprise");
                this.syncEnterpriseLoad = false;
            } catch (error) {
                this.syncEnterpriseLoad = false;
            }
        },
        async solutionEnterprise(row) {
            try {
                await this.$confirm("是否解绑企业?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                const { data: res } = await this.$http.post("/admin/UserGuest/unBindEnterprise", { id: row.id });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            } catch (error) {}
        },
        onDownload() {
            location.href = "https://admin.yfchuhai.com/xlsx/tpl.xlsx";
        },
        reset() {
            // console.log();
            this.form_2 = this.$options.data.call(this).form_2;
            this.currentPage = 1;
            this.currentPage2 = 1;
            this.getlist();
            this.reveal = true;
        },
        fEnterpriseId(val) {
            if (val.enterpriseId > 0) {
                return "已绑定";
            }
            return "未绑定";
        },
        handleCurrentChange2(e) {
            this.currentPage2 = e;
            this.get_data();
        },
        fUser(row) {
            if (this.$toData(row.user)) {
                if (row.user.certStatus == 2) {
                    return "已认证";
                }
                if (row.user.completeInfo == 1) {
                    return "已完善";
                }
                return "已注册";
            }
            return "";
        },
        fUserColor(row) {
            if (this.$toData(row.user)) {
                if (row.user.certStatus == 2) {
                    return "success";
                }
                if (row.user.completeInfo == 1) {
                    return "";
                }
                return "warning";
            }
            return "";
        },
        showinput(row, val) {
            row.pai = true;
            if (val == 2) {
                this.table = [...this.table];
            } else if (val == 3) {
                this.phonelist = [...this.phonelist];
            } else {
                this.list = [...this.list];
            }
            setTimeout(() => {
                $(".id input").focus();
            }, 20);
        },
        showinput_1(row, val) {
            row.pai_1 = true;
            if (val == 2) {
                this.table = [...this.table];
            } else if (val == 3) {
                this.phonelist = [...this.phonelist];
            } else {
                this.list = [...this.list];
            }
            setTimeout(() => {
                $(".id input").focus();
            }, 20);
        },
        handleInputBlur(row, val) {
            this.$http.post("/admin/UserGuest/editRemark", row).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    if (val == 2) {
                        this.get_data();
                    } else if (val == 3) {
                        this.getphoneid(row);
                    } else {
                        this.getlist();
                    }
                }
            });
        },
        async Stype(row, val) {
            const res = await editInfo({
                ...row,
                type: row.type.toString(),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                if (val == 2) {
                    this.get_data();
                } else if (val == 3) {
                    this.getphoneid(row);
                } else {
                    this.getlist();
                }
            }
        },
        async Sbusiness(row, val) {
            const res = await editInfo({
                ...row,
                business: row.business.join(","),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                if (val == 2) {
                    this.get_data();
                } else if (val == 3) {
                    this.getphoneid(row);
                } else {
                    this.getlist();
                }
            }
        },
        async toSync() {
            let { data: res } = await this.$http.post("/admin/UserGuest/sync", {
                type: this.registeringSynchronization,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.pop.show = false;
                this.reset();
            }
        },
        handleInputBlur_1(row, val) {
            this.$http.post("/admin/UserGuest/editInfo", row).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    if (val == 2) {
                        this.get_data();
                    } else if (val == 3) {
                        this.getphoneid(row);
                    } else {
                        this.getlist();
                    }
                }
            });
        },
        show_ex(row) {
            this.phone_data = true;
            setTimeout(() => {
                this.getphoneid(row);
            }, 200);
        },
        async getphone(row) {
            // var that=this
            console.log(row);
            const { data: res } = await this.$http.get("/admin/UserGuest/getListByPhone", {
                params: {
                    phone: row.phone,
                },
            });

            if (res.errorCode == 200) {
                // Vue.set(this.phonelist, index, res.data.list)
                this.phonelist = [...res.data.list];
                this.phonelist.forEach((item) => {
                    if (item.waId.length > 0) {
                        item.waId = item.waId.split(",");
                    }
                });
                // this.data.phonelist = res.data.list
                // that.$set('phonelist',res.data.list)
                // console.log(this.phonelist);
            }
        },
        show_s() {
            this.reveal = !this.reveal;
            this.get_data();
        },
        async get_data() {
            const { data: res } = await this.$http.get("/admin/UserGuest/getRedundantList", {
                params: {
                    page: this.currentPage2,
                    pageSize: 10,
                },
            });
            if (res.errorCode == 200) {
                this.table = res.data.list;
                this.total2 = res.data.totalCount;
                this.table.forEach((item) => {
                    if (item.waId.length > 0) {
                        item.waId = item.waId.split(",");
                    }
                    if (item.business.trim().length > 0) {
                        item.business = item.business.split(",");
                    } else {
                        item.business = [];
                    }
                    if (item.type == 0) {
                        item.type = "";
                    } else {
                        item.type = item.type.toString().split(",");
                    }
                    // if (item.jobLevel == 0) {
                    //     item.jobLevel = ''
                    // }
                });
            }
        },
        Import(val) {
            var that = this;
            if (this.impEX == "导入Excel") {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = ".xlsx";

                input.click();
                input.onchange = function() {
                    // console.log(this.files[0])
                    var file = this.files[0];
                    var formData = new FormData();
                    formData.append("file", file);
                    var url;
                    if (val == "new") {
                        url = "/admin/UserGuest/importExcelNew";
                        that.importExcel = true;
                    } else {
                        url = "/admin/UserGuest/importExcel";
                    }
                    that.$http.post(url, formData).then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            that.$message.success(res.message);
                        } else {
                            that.$message.error(res.message);
                        }
                        if (val != "new") {
                            that.impEX = "导入Excel";
                        } else {
                            that.importExcel = false;
                        }
                    });
                    if (val != "new") {
                        that.impEX = "导入中";
                    }
                };
            }
        },
        async getp() {
            const jj = await this.$store.dispatch("getProvince");
            this.p_arr = jj.data;
        },
        async getc(val) {
            this.form_2.city = "";

            const jj = await this.$store.dispatch("getCity", val);
            if (jj.errorCode == 200) {
                this.c_arr = jj.data;
            }
        },
        subimt() {
            this.currentPage = 1;
            this.form_2.page = 1;
            this.getlist();
            this.reveal = true;
        },
        check() {
            console.log(1);
        },
        information(row) {
            this.showinfo = true;
            // this.form_show = row
            this.$http
                .get("/admin/UserGuest/getById?id", {
                    params: {
                        id: row.id,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.form_show = res.data;
                    }
                });
        },
        datum(row) {
            location.href = this.baseurl + this.yu + row.document;
        },
        sortChange(c) {
            console.log(c);
            if (c.order) {
                var s = "activity_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "report_order_num";
                } else if (c.prop == "views") {
                    s = "report_views";
                } else {
                    s = "report_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getlist();
        },

        addkuai() {
            this.addkuai = false;
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                }
            });
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        handleSizeChange(e) {
            this.pagesize = e;
            console.log(e);
        },
        handleCurrentChange(e) {
            this.currentPage = e;
            this.form_2.page = e;
            this.getlist();
        },
        getlist() {
            this.$http
                .get("/admin/UserGuest/getList", {
                    params: {
                        ...this.form_2,
                        completeTime: this.form_2.completeTime.join("~"),
                        createTime: this.form_2.createTime.join("~"),
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        // console.log(res)
                        this.list = res.data.list;
                        this.list.forEach((item) => {
                            if (item.waId.length > 0) {
                                item.waId = item.waId.split(",");
                            }
                            if (item.business.trim().length > 0) {
                                item.business = item.business.split(",");
                            } else {
                                item.business = [];
                            }
                            // if(item.business.trim().length>0)
                            if (item.type == 0) {
                                item.type = "";
                            } else {
                                item.type = item.type.toString().split(",");
                            }
                            // if (item.jobLevel == 0) {
                            //     item.jobLevel = ''
                            // }
                        });
                        // console.log(this.list)
                        this.total = res.data.totalCount;
                        this.total_form.allTotal = res.data.allTotal ? res.data.allTotal : 0;
                        this.total_form.existPhoneTotal = res.data.existPhoneTotal;
                        // this.getarea()
                        this.list.forEach((item) => {
                            this.pai.push({
                                id: item.id,
                                pai: false,
                            });
                        });
                        // this.getea()
                    }
                });
        },
        checkf(val) {
            this.form_3 = val;
            // console.log(this.form_3)
        },
        async beforedown() {
            // const phone = this.$cookies.get('phone')
            const phone = "15080329619";
            if (!phone) {
                this.$message.error("没有绑定手机号");
                return;
            }
            if (!this.proof) {
                this.dioA = true;
            } else {
                this.download();
            }
        },
        async download(val) {
            if (this.btn) {
                this.btn = false;
                // const { data: res } = await this.$http.get(
                //   '/admin/Common/checkSmsCode',
                //   {
                //     params: {
                //       ...this.form_3
                //     }
                //   }
                // )
                console.log(val);
                // console.log(this.btn)
                // this.$forceUpdate()
                if (false) {
                    // this.$message.error(res.message)
                    // return
                } else {
                    const { data: res } = await this.$http.get("/admin/UserGuest/exportExcel", {
                        params: {
                            ...this.form_2,
                            ...this.form_3,
                        },
                    });
                    // console.log();
                    if (res.errorCode == 200) {
                        this.proof = true;
                    }

                    // console.log(1);
                    this.btn = true;

                    // console.log(msg.data.url);
                    // let url = window.URL.createObjectURL(msg.data) //表示一个指定的file对象或Blob对象
                    // console.log(url,"看一下这是啥")
                    // let a = document.createElement('a')
                    // document.body.appendChild(a)
                    // console.log(msg.data)
                    // let url = msg.data.url
                    // console.log(url)
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        location.href = res.data.url;
                        this.dioA = false;
                    }
                    // a.href = url
                    // a.download //命名下载名称
                    // a.click() //点击触发下载
                    // window.URL.revokeObjectURL(url)
                    // this.dioA = false
                }
            }
        },
        add() {},
        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                this.form.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },

        // beforeAvatarUpload(file) {
        //   const isJPG = file.type === 'image/jpeg'
        //   const isLt2M = file.size / 1024 / 1024 < 2

        //   if (!isJPG) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!')
        //   }
        //   if (!isLt2M) {
        //     this.$message.error('上传头像图片大小不能超过 2MB!')
        //   }
        //   return isJPG && isLt2M
        // },
        handleAvatarSuccess_1(res, file) {
            if (res.errorCode == 200) {
                this.form_1.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },
        beforeAvatarUpload_1(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },

        addedit() {
            if (this.btn) {
                // this.form.picture = this.form.picture.slice(this.baseurl.length)

                this.$http.post("/admin/News/edit", this.form_1).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.dialogVisiblexui = false;
                        this.$message.success(res.message);
                        this.getlist();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
        edit(id) {
            var qq = document.querySelector(".v-modal");
            if (qq) {
                this.phone_data = false;
            }
        },
        S_jobLevel(row, val) {
            this.$http
                .post("/admin/UserGuest/editInfo", {
                    id: row.id,
                    jobLevel: row.jobLevel,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        if (val == 2) {
                            this.get_data();
                        } else if (val == 3) {
                            this.getphoneid(row);
                        } else {
                            this.getlist();
                        }
                    }
                });
        },
        open(id, val, row) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http
                        .delete("/admin/UserGuest/del", {
                            params: {
                                id: id,
                            },
                        })
                        .then((res) => {
                            //   console.log(res)
                            if (res.data.errorCode == 200) {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                if (this.total % 10 == 1) {
                                    this.form_2.page--;
                                }
                                if (val == 1) {
                                    this.getlist();
                                } else {
                                    this.get_data();
                                    this.getlist();
                                    this.getphoneid(row);
                                }
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        getphoneid(val) {
            this.$http
                .get("/admin/UserGuest/getListById", {
                    params: {
                        id: val.id,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.phonelist = res.data.list;
                        this.phonelist.forEach((item) => {
                            if (item.waId.length > 0) {
                                item.waId = item.waId.split(",");
                            }
                            if (item.business.trim().length > 0) {
                                item.business = item.business.split(",");
                            } else {
                                item.business = [];
                            }
                            if (item.type == 0) {
                                item.type = "";
                            } else {
                                item.type = item.type.toString().split(",");
                            }
                            // if (item.jobLevel == 0) {
                            //     item.jobLevel = ''
                            // }
                        });
                    }
                });
        },
        async getType(val) {
            if (val == 1) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=`
                );
                this.businessList = res.data.list;
                return;
            }
            if (val == 2) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form_2.type}&businessId=${this.form_2.business}`
                );
                this.subBusinessList = res.data.list;
                return;
            }
            var { data: res } = await this.$http.get(
                "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
            );
            this.typeList = res.data.list;
        },
        unfold(val) {
            this.phone_data = true;
            this.getphoneid(val);
        },
        show(id, el) {
            this.$http
                .post("/admin/Report/publish", {
                    id: id,
                    publishStatus: el,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.getlist();
                    }
                });
        },
      visibleChange(e){
          if(e){
            return
          }else {
            this.$confirm('是否将小助手同步至微信会员和高管库', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning'
            }).then(() => {
              this.$http
                  .post("/admin/UserGuest/editService", {
                    ...this.row,
                    waId: this.row.waId.join(","),
                    sync: 1
                  })
                  .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                      this.getlist();
                    } else {
                      this.getlist();
                    }
                  });

            }).catch(() => {
              this.$http
                  .post("/admin/UserGuest/editService", {
                    ...this.row,
                    waId: this.row.waId.join(","),
                    sync: 0
                  })
                  .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                      this.getlist();
                    } else {
                      this.getlist();
                    }
                  });

            });

          }

},
        chagne(row, val) {
          this.row=row
          if(val==3){
            this.$http
                .post("/admin/UserGuest/editService", {
                  ...row,
                  waId: row.waId.join(","),
                })
                .then(({ data: res }) => {
                  if (res.errorCode == 200) {
                    if (val == 3) {
                      this.getphoneid(row);
                    } else {
                      this.getlist();
                    }
                  } else {
                    if (val == 3) {
                      this.getphoneid();
                    } else {
                      this.getlist();
                    }
                  }
                });

          }


        },
    },
};
</script>

<style lang="less" scoped>
.out {
    // width: 500px;

    margin-bottom: 20px;

    .input {
        width: 37%;
        height: 36px;
        display: inline-block;
        margin-right: 30px;
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.skyblue {
    color: skyblue;
}

.green {
    color: green;
}

.yellow {
    color: pink;
}

.red {
    color: red;
}

.out .input {
    width: 18%;
}

.el-card {
    position: relative;
}

.out .right {
    float: right;
}

.out_sp {
    display: inline-block;
    margin-right: 10px;
}
</style>
